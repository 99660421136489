<template>
  <div class="form">
    <div class="form_con" id="forms2">

      <div class="list list_two list_noborder">
        <ul v-if="list!=null">
          <li v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
            <div class="thumb">
              <div class="thumb_img"><img :src="item.thumb" /></div>
              
            </div>
            <div class="title">{{item.title}}</div>
          </li>
         
        </ul>
        <el-empty v-if="!list  || list.length<=0" description="暂无数据"></el-empty>
      </div>
     <page
          v-if="total > 10"
          :page="page"
          :limit="limit"
          :total="total"
          ref="page"
        ></page>
     </div>
  </div>
</template>
<script>
import page from "../../components/page.vue";
export default {
  name: "matchList",
  data() {
    return {
      events_id:'',
      list:null,
      page:1,
      limit:10,
      total:0
    };
  },
  components:{
    page
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.$parent.routerIndex = 7;
    this.getList();
  },
  methods: {
    getList() {
      if (this.$refs.page && this.page == 1) {
        this.page == 1
        this.$refs.page.currentPage = 1;
      }
      console.log(this.$face.events_list);
      this.$api.POST(this.$face.events_list, {page: this.page,limit: this.limit}, (res) => {
        if(res.code==200){
          this.list=res.data.list;
          this.total=res.data.total;
        }
      });
    },
    toDetail(id){
      let routeData = this.$router.resolve({
          name: "matchIndex",
          query: {
            events_id: id
          },
        });
        window.open(routeData.href, "_blank");
    }

  },
  activated() {
    console.log(this.scroll);
    this.$nextTick(() => {
      this.$parent.routerIndex = 7;
    })
  }
};
</script>

<style scoped>
.form {
  overflow: hidden;
}
.form_con {
  width: 1380px;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 20px auto;
  min-height: 800px;
}
.form_con h2 {
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px #bfbfbf solid;
  margin-bottom: 20px;
}
.form_con {
  overflow: hidden;
}
.list{ padding-top: 30px; width: 100%; overflow: hidden; border-bottom: 1px #dfdfdf solid;padding-bottom: 20px;}


.list h3{ font-size: 30px; color: #333;padding-left: 22px; margin-bottom: 20px;}
.list_two ul{ display: flex;}
.list_two li{ width: 680px;height: 320px; background: #fff; float: left; margin-right: 20px; border-radius: 8px; overflow: hidden; cursor: pointer;}
.list_two li .thumb{ width: 680px; height: 270px; background: none;}
.list_two li .thumb .thumb_img{ width: 680px;}
.list_two li .thumb img{ width: 100%;} 
.list_two li .title{ font-size: 16px; line-height: 50px;padding-left: 12px;}
.list_noborder{ border-bottom: none;}
</style>